<template>
    <component
        :is="props.tag"
        ref="root"
        :class="{
            'locked-scroll': !props.disabled,
            'locked-scroll--fluid': !props.disabled && props.fluid
        }"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

    export interface Props {
        tag?: string;
        disabled?: boolean;
        fluid?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        tag: 'div',
        disabled: false,
        fluid: false,
    });

    const root = ref<HTMLElement | null>(null);

    const disableScroll = () => {
        disableBodyScroll(root.value!, {
            reserveScrollBarGap: true,
            allowTouchMove: (el: HTMLElement | Element) => el.tagName === 'TEXTAREA',
        });
    };

    const enableScroll = () => {
        enableBodyScroll(root.value!);
    };

    // Disable body scroll when the component is mounted
    onMounted(() => {
        if (!props.disabled) {
            disableScroll();
        }
    });

    // Enable body scroll when the component is unmounted
    onBeforeUnmount(() => {
        enableScroll();
    });

    // Enable or disable body scroll when the `disabled` prop changes
    watch(() => props.disabled, newValue => {
        if (newValue) {
            enableScroll();
        } else {
            disableScroll();
        }
    });
</script>
