<template>
    <teleport to="body">
        <div class="modal">
            <div
                v-click-outside="vcoConfig"
                class="modal__window"
                :class="{
                    'modal__window--md': props.size === 'md',
                    'modal__window--lg': props.size === 'lg',
                }"
                role="dialog"
                aria-labelledby="modal-heading"
            >
                <header class="modal__header">
                    <slot name="header">
                        <div class="flex justify-between items-center">
                            <h2
                                id="modal-heading"
                                class="h4 text-white truncate"
                            >
                                {{ props.title }}
                            </h2>

                            <button
                                type="button"
                                class="modal__close"
                                @click.prevent="onClose"
                            >
                                <font-awesome-icon
                                    :icon="['fal', 'times']"
                                    size="lg"
                                />
                            </button>
                        </div>
                    </slot>
                </header>

                <locked-scroll
                    tag="section"
                    class="modal__body"
                    :disabled="!props.enableLockedScroll"
                    :class="{
                        'modal__body--padded': props.padding,
                        'modal__body--locked-scroll': props.enableLockedScroll,
                    }"
                >
                    <slot />
                </locked-scroll>

                <footer
                    v-if="$slots.footer"
                    class="modal__footer"
                >
                    <slot name="footer" />
                </footer>
            </div>
        </div>
    </teleport>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faTimes } from '@fortawesome/pro-light-svg-icons';
    import hotkeys from 'hotkeys-js';

    library.add(faTimes);

    export interface Props {
        title: string;
        padding?: boolean;
        size?: 'md' | 'lg';
        enableLockedScroll?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        padding: true,
        size: 'md',
        enableLockedScroll: true,
    });

    const emits = defineEmits<{
        close: [void]
    }>();

    const onClose = () => emits('close');

    const vcoConfig = {
        events: [ 'mousedown' ],
        handler: onClose,
    };

    onMounted(() => {
        hotkeys('esc', onClose);
    });

    onUnmounted(() => {
        hotkeys.unbind('esc', onClose);
    });
</script>

<style lang="postcss">
    .modal {
        @apply fixed inset-0 z-[999] flex justify-center bg-black/50 px-4;

        &__window {
            @apply relative bg-body rounded-lg shadow-2xl my-auto w-full max-w-2xl;
            @apply dark:bg-gray-900;

            &--lg {
                @apply max-w-4xl;
            }
        }

        &__header {
            @apply px-4 lg:px-6 py-2 bg-primary-500 text-white rounded-t-lg;
            @apply dark:bg-primary-700;
        }

        &__footer {
            @apply p-4 lg:p-6 border-t border-gray-200 rounded-b-lg text-right;
            @apply dark:border-gray-800;
        }

        &__body {
            &--padded {
                @apply px-4 lg:px-6 py-6 lg:py-8;
            }

            &--locked-scroll {
                @apply overflow-auto;

                max-height: calc(100vh - 14rem);
                max-height: calc(100dvh - 10rem);
            }
        }

        &__close {
            @apply rounded px-3 py-2 -mr-3 hover:bg-primary-600 dark:hover:bg-primary-800;

            &:focus {
                @apply outline-none;
            }
        }
    }
</style>
